import React, { Component } from "react";

import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";

import { Link } from "react-router-dom";

import Select from "react-select";
import { deleteDoctor } from "../../store/actions/doctorActions";

import { Redirect } from "react-router-dom";

import "./DoctorPicker.css";

const mapStateToProps = (state, ownState) => {
  return {
    doctors: state.firestore.data.doctors,
    profile: state.firebase.profile,
    history: ownState.history
  };
};

const mapDispatchToProps = dispatch => {
  return {
    deleteDoctor: (key, name) => {
      if (window.confirm(`¿Está seguro de borrar al doctor ${name}?`)) {
        dispatch(deleteDoctor(key));
        return true;
      }

      return false;
    }
  };
};

const findDoctor = (options, id) => {
  if (id === "") {
    return { value: "Todos", label: "Todos", id: "" };
  }
  return options.find(doctor => {
    return doctor.id === id;
  });
};

export class DoctorPicker extends Component {
  constructor(props) {
    super(props);

    let options = [{ value: "Todos", label: "Todos", id: "" }];
    if (this.props.doctors) {
      for (let key in this.props.doctors) {
        if (this.props.doctors[key]) {
          let newDoctor = {};
          newDoctor["label"] = this.props.doctors[key].name;
          newDoctor["value"] = this.props.doctors[key].name;
          newDoctor["id"] = key;
          options.push(newDoctor);
        }
      }
    }

    this.state = {
      options: options,
      doctor: this.props.doctor || "",
      selectedDoctor: findDoctor(options, this.props.doctor || "")
    };
  }

  handleDelete = e => {
    e.preventDefault();

    if (
      this.props.deleteDoctor(
        this.state.doctor,
        findDoctor(this.state.options, this.state.doctor).value
      )
    ) {
      let newOptions = this.state.options.filter(option => {
        return option.id !== this.state.doctor;
      });
      this.setState({ options: newOptions });
    }
  };

  componentWillReceiveProps(nextProps) {
    let options = [{ value: "Todos", label: "Todos", id: "" }];

    for (let key in nextProps.doctors) {
      if (nextProps.doctors[key]) {
        let newDoctor = {};
        newDoctor["label"] = nextProps.doctors[key].name;
        newDoctor["value"] = nextProps.doctors[key].name;
        newDoctor["id"] = key;
        options.push(newDoctor);
      }
    }

    if (nextProps.doctor !== this.props.doctor) {
      this.setState({
        options: options,
        doctor: nextProps.doctor,
        selectedDoctor: findDoctor(options, nextProps.doctor || "")
      });
    } else {
      this.setState({
        options: options,
        selectedDoctor: findDoctor(options, this.props.doctor || "")
      });
    }
  }

  render() {
    return (
      <div className="doctor-select-wrapper">
        <div>
          <Link to="/addDoctor">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path
                className="heroicon-ui"
                d="M19 10h2a1 1 0 0 1 0 2h-2v2a1 1 0 0 1-2 0v-2h-2a1 1 0 0 1 0-2h2V8a1 1 0 0 1 2 0v2zM9 12A5 5 0 1 1 9 2a5 5 0 0 1 0 10zm0-2a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm8 11a1 1 0 0 1-2 0v-2a3 3 0 0 0-3-3H7a3 3 0 0 0-3 3v2a1 1 0 0 1-2 0v-2a5 5 0 0 1 5-5h5a5 5 0 0 1 5 5v2z"
              />
            </svg>
          </Link>
          {this.props.profile.isLoaded &&
            this.props.profile.admin &&
            this.state.doctor && (
              <Link
                to={{
                  pathname: "/editDoctor",
                  state: {
                    doctor: this.props.doctors
                      ? this.props.doctors[this.state.doctor]
                      : {},
                    editing: true,
                    id: this.state.doctor
                  }
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path
                    class="heroicon-ui"
                    d="M6.3 12.3l10-10a1 1 0 0 1 1.4 0l4 4a1 1 0 0 1 0 1.4l-10 10a1 1 0 0 1-.7.3H7a1 1 0 0 1-1-1v-4a1 1 0 0 1 .3-.7zM8 16h2.59l9-9L17 4.41l-9 9V16zm10-2a1 1 0 0 1 2 0v6a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6c0-1.1.9-2 2-2h6a1 1 0 0 1 0 2H4v14h14v-6z"
                  />
                </svg>
              </Link>
            )}
          {this.props.profile.isLoaded &&
            this.props.profile.admin &&
            this.state.doctor && (
              <Link to="">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  onClick={this.handleDelete}
                  data-id={this.state.doctor}
                >
                  <path
                    class="heroicon-ui"
                    data-id={this.state.doctor}
                    d="M8 6V4c0-1.1.9-2 2-2h4a2 2 0 0 1 2 2v2h5a1 1 0 0 1 0 2h-1v12a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V8H3a1 1 0 1 1 0-2h5zM6 8v12h12V8H6zm8-2V4h-4v2h4zm-4 4a1 1 0 0 1 1 1v6a1 1 0 0 1-2 0v-6a1 1 0 0 1 1-1zm4 0a1 1 0 0 1 1 1v6a1 1 0 0 1-2 0v-6a1 1 0 0 1 1-1z"
                  />
                </svg>
              </Link>
            )}
        </div>
        <Select
          className="doctor-select select-input"
          options={this.state.options}
          value={this.state.selectedDoctor}
          name="doctor-select"
          noOptionsMessage={() => "No hay más opciones"}
          classNamePrefix="react-select"
          onChange={e => {
            if (e.id === "") {
              this.props.history.push("/");
            } else {
              this.props.history.push("/doctors/" + e.id);
            }
          }}
        />
      </div>
    );
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([{ collection: "doctors" }])
)(DoctorPicker);
