import React, { Component } from "react";

import { connect } from "react-redux";
import { editPatient } from "../../store/actions/patientActions";

const mapDispatchToProps = dispatch => {
  return {
    editPatient: (patientID, patient) => {
      dispatch(editPatient(patientID, patient));
    }
  };
};

export class Paciente extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id || "",
      name: props.name || "",
      mail: props.mail || "",
      phone: props.phone || "",
      editMode: false
    };
  }

  handleChange = e => {
    e.preventDefault();
    this.setState({ [e.target.id]: e.target.value });
  };

  handleEdit = e => {
    e.preventDefault();
    this.setState({ editMode: !this.state.editMode });
  };

  handleSubmit = e => {
    let id = this.state.id;
    let patient = {
      name: this.state.name,
      mail: this.state.mail,
      phone: this.state.phone
    };

    this.props.editPatient(id, patient);
    this.setState({ editMode: !this.state.editMode });
  };

  render() {
    if (this.state.editMode) {
      return (
        <tr>
          <td>
            <input
              id="name"
              type="text"
              value={this.state.name}
              onChange={this.handleChange}
            />
          </td>
          <td>
            <input
              id="mail"
              type="email"
              value={this.state.mail}
              onChange={this.handleChange}
            />
          </td>
          <td>
            <input
              id="phone"
              type="text"
              value={this.state.phone}
              onChange={this.handleChange}
            />
          </td>
          <td className="td-icon">
            {" "}
            <i className="material-icons" onClick={this.handleSubmit}>
              send
            </i>
          </td>
        </tr>
      );
    }
    return (
      <tr>
        <td>{this.state.name}</td>
        <td>
          <a href={`mailto:${this.state.mail}`}>{this.state.mail}</a>
        </td>
        <td>{this.state.phone}</td>
        <td className="td-icon">
          {" "}
          <i className="material-icons" onClick={this.handleEdit}>
            edit
          </i>
        </td>
      </tr>
    );
  }
}

export default connect(
  null,
  mapDispatchToProps
)(Paciente);
