const initState = {
  appointments: []
};

const appointmentReducer = (state = initState, action) => {
  switch (action.type) {
    case "CREATE_APPOINTMENT":
      console.log("added appointment", action.appointment);
      return state;
    case "CREATE_APPOINTMENT_ERROR":
      console.log("error adding appointment", action.error);
      return state;
    case "DELETE_APPOINTMENT":
      console.log("deleted appointment", action.appointmentID);
      return state;
    case "DELETE_APPOINTMENT_ERROR":
      console.log("error deleting appointment", action.error);
      return state;
    case "EDIT_APPOINTMENT":
      console.log("edited appointment", action.appointmentID);
      return state;
    case "EDIT_APPOINTMENT_ERROR":
      console.log("error editing appointment", action.error);
      return state;
    case "CHANGE_PERCENTAGE":
      console.log("Changed percentage to ", action.percentage);
      return state;
    case "CHANGE_PERCENTAGE_ERROR":
      console.log("Error changing percentage", action.error);
      return state;
    default:
      return state;
  }
};

export default appointmentReducer;
