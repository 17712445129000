export const createAppointment = appointment => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    if (!appointment.start && !appointment.end) {
      dispatch({
        type: "CREATE_APPOINTMENT_ERROR",
        error: {}
      });
    }

    firestore
      .collection("appointments")
      .add({
        ...appointment,
        createdAt: new Date(),
        charged: 0,
        finished: false,
        confirmed: false
      })
      .then(() => {
        dispatch({
          type: "CREATE_APPOINTMENT",
          appointment
        });
      })
      .catch(error => {
        dispatch({
          type: "CREATE_APPOINTMENT_ERROR",
          error
        });
      });
  };
};

export const deleteAppointment = appointmentID => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection("appointments")
      .doc(appointmentID)
      .delete()
      .then(() => {
        dispatch({
          type: "DELETE_APPOINTMENT",
          appointmentID
        });
      })
      .catch(error => {
        dispatch({
          type: "DELETE_APPOINTMENT_ERROR",
          error
        });
      });
  };
};

export const editAppointment = (appointmentID, appointment) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection("appointments")
      .doc(appointmentID)
      .set(
        {
          ...appointment
        },
        { merge: true }
      )
      .then(() => {
        dispatch({
          type: "EDIT_APPOINTMENT",
          appointmentID
        });
      })
      .catch(error => {
        dispatch({
          type: "EDIT_APPOINTMENT_ERROR",
          error
        });
      });
  };
};

export const changePercentage = percentage => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection("percentage")
      .doc("FWlYR3YFmUHXLRDhIFmB")
      .set({ value: percentage })
      .then(() => {
        dispatch({
          type: "CHANGE_PERCENTAGE",
          percentage
        });
      })
      .catch(error => {
        dispatch({
          type: "CHANGE_PERCENTAGE_ERROR",
          error
        });
      });
  };
};
