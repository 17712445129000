import authReducer from "./authReducer";
import appointmentReducer from "./appointmentReducer";
import doctorReducer from "./doctorReducer";
import currentDoctorReducer from "./currentDoctorReducer";
import patientReducer from "./patientReducer";
import especialidadesReducer from "./especialidadesReducer";
import { firestoreReducer } from "redux-firestore";
import { firebaseReducer } from "react-redux-firebase";

import { combineReducers } from "redux";

const rootReducer = combineReducers({
  auth: authReducer,
  appointment: appointmentReducer,
  doctor: doctorReducer,
  currentDoctor: currentDoctorReducer,
  patient: patientReducer,
  specialties: especialidadesReducer,
  firestore: firestoreReducer,
  firebase: firebaseReducer
});

export default rootReducer;
