import React, { Component } from "react";

import M from "materialize-css";
import moment from "moment";

import firebase from "../../config/fbConfig";

import "./ReporteGenerator.css";

export class ReporteGenerator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      start: new Date(
        new Date(new Date().setHours(0, 0, 0, 0)).setMonth(
          new Date().getMonth() - 1
        )
      ),
      end: new Date(new Date().setHours(0, 0, 0, 0))
    };
  }
  openDatePicker(e) {
    var instance = M.Datepicker.getInstance(e.target);
  }

  generarReporte = e => {
    e.preventDefault();
    let generate = firebase.functions().httpsCallable("generarReporte");
    document.getElementsByTagName("body")[0].classList.add("loading");

    generate(JSON.stringify(this.state))
      .then(function(result) {
        document.getElementsByTagName("body")[0].classList.remove("loading");
        window.open(result.data[0]);
      })
      .catch(function(error) {
        // Getting the Error details.
        var code = error.code;
        var message = error.message;
        var details = error.details;
        // ...

        console.log(code, message, details);
      });
  };
  componentDidMount() {
    let optionsStart = {
      setDefaultDate: true,
      defaultDate: new Date(this.state.start),
      autoClose: true,
      maxDate: new Date(),
      onOpen: () => {
        console.log("open");
      },
      onSelect: selected => {
        this.setState({ start: selected });
        M.Datepicker.getInstance(document.getElementById("end")).open();
      },
      format: "mmmm dd, yyyy",
      i18n: {
        cancel: "Cancelar",
        clear: "Limpiar",
        done: "OK",
        months: moment.months(),
        monthsShort: moment.monthsShort(),
        weekdays: moment.weekdays(),
        weekdaysShort: moment.weekdaysShort(),
        weekdaysAbbrev: moment.weekdaysShort()
      }
    };

    let optionsEnd = {
      setDefaultDate: true,
      defaultDate: this.state.end,
      maxDate: new Date(),
      autoClose: true,
      onOpen: () => {
        console.log("open");
      },
      onSelect: selected => {
        this.setState({ end: selected });
      },
      format: "mmmm dd, yyyy",
      i18n: {
        cancel: "Cancelar",
        clear: "Limpiar",
        done: "OK",
        months: moment.months(),
        monthsShort: moment.monthsShort(),
        weekdays: moment.weekdays(),
        weekdaysShort: moment.weekdaysShort(),
        weekdaysAbbrev: moment.weekdaysShort()
      }
    };
    var start = document.getElementById("start");
    var end = document.getElementById("end");

    M.Datepicker.init(start, optionsStart);
    M.Datepicker.init(end, optionsEnd);
  }
  render() {
    return (
      <div className="generate-report-outer-wrapper">
        <h5>Generar reportes</h5>
        <div className="generate-report-wrapper">
          <div>
            <input
              type="text"
              id="start"
              className="datepicker"
              onClick={this.openDatePicker}
              placeholder="Fecha inicial"
            />
            <input
              type="text"
              id="end"
              className="datepicker"
              onClick={this.openDatePicker}
              placeholder="Fecha final"
            />
          </div>
        </div>
        <div className="button-wrapper">
          <a
            href="/"
            onClick={this.generarReporte}
            id="generate-report"
            className="waves-effect btn"
          >
            Generar reporte
          </a>
        </div>
      </div>
    );
  }
}

export default ReporteGenerator;
