const initState = {
  specialties: []
};

const especialidadesReducer = (state = initState, action) => {
  switch (action.type) {
    case "CREATE_ESPECIALIDAD":
      console.log("added especialidad", action.especialidad);
      return state;
    case "CREATE_ESPECIALIDAD_ERROR":
      console.log("error adding especialidad", action.error);
      return state;
    case "DELETE_ESPECIALIDAD":
      console.log("removed especialidad", action.especialidadID);
      return state;
    case "DELETE_ESPECIALIDAD_ERROR":
      console.log("error removing especialidad", action.error);
      return state;
    case "EDIT_ESPECIALIDAD":
      console.log("edited especialidad", action.especialidadID);
      return state;
    case "EDIT_ESPECIALIDAD_ERROR":
      console.log("error editing especialidad", action.error);
      return state;
    default:
      return state;
  }
};

export default especialidadesReducer;
