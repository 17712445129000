import React from "react";
import { Link } from "react-router-dom";

import "./EditAppointment.css";

export default function EditAppointment({
  selected,
  edit,
  close,
  handleDataChange,
  moneyCharged
}) {
  return (
    <div id="edit-appointment-modal" className="modal modal-fixed-footer">
      <div className="modal-content row">
        <form className="col s12">
          <div className="input-field col s12">
            <input
              id="appointment_charged"
              type="number"
              className="validate"
              data-statetarget="moneyCharged"
              value={moneyCharged || ""}
              onChange={handleDataChange}
              required
              placeholder={`Precio sugerido: $${
                selected.doctor ? selected.doctor.price : "0"
              } MXN`}
              autoFocus
            />
            <label htmlFor="appointment_charged">Dinero cobrado</label>
            <span
              className="helper-text"
              data-error="Se necesita ingresar un número"
              data-success="Correcto"
            >
              Ingresa un número
            </span>
          </div>
        </form>
      </div>
      <div className="modal-footer">
        <Link
          to=""
          onClick={close}
          id="edit-appointment-cancel-button"
          className="modal-close btn-small btn-flat btn"
        >
          Cancelar
        </Link>
        <Link
          to=""
          id="tarjeta-appointment-button"
          className="waves-effect waves-blue btn-small btn"
          onClick={edit}
        >
          Tarjeta
        </Link>
        <Link
          to=""
          id="efectivo-appointment-button"
          className="waves-effect waves-blue btn-small btn"
          onClick={edit}
        >
          Efectivo
        </Link>
      </div>
    </div>
  );
}
