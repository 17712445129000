import React, { Component } from "react";
import { connect } from "react-redux";
import { signIn } from "../../store/actions/authActions";
import { Redirect } from "react-router-dom";

import Spinner from "react-spinkit";

import "./Auth.css";

class SignIn extends Component {
  state = {
    email: "",
    password: ""
  };
  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };
  handleSubmit = e => {
    e.preventDefault();
    this.props.signIn(this.state);
  };

  render() {
    if (!this.props.profile.isLoaded) {
      return (
        <div className="spinner-outer-wrapper">
          <div className="spinner-inner-wrapper">
            <Spinner name="ball-scale-ripple" />
            Cargando
          </div>
        </div>
      );
    }

    if (this.props.profile.admin) {
      return <Redirect to="/admin" />;
    }

    if (this.props.auth.uid) {
      return <Redirect to="/" />;
    }

    return (
      <div id="signin-modal" className="modal">
        <form className="modal-content" onSubmit={this.handleSubmit}>
          <h5 className="grey-text text-darken-3">Iniciar sesión</h5>
          <div className="input-field">
            <label htmlFor="email">Email</label>
            <input type="email" id="email" onChange={this.handleChange} />
          </div>
          <div className="input-field">
            <label htmlFor="password">Contraseña</label>
            <input type="password" id="password" onChange={this.handleChange} />
          </div>
          <div className="input-field button">
            <button className="btn pink lighten-1 z-depth-0 right">
              Iniciar sesión
            </button>
            <div className="red-text">
              {this.props.authError ? <p>{this.props.authError}</p> : ""}
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    signIn: credentials => {
      dispatch(signIn(credentials));
    }
  };
};

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    authError: state.auth.authError
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignIn);
