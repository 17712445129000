import React, { Component } from "react";

import M from "materialize-css";

import "./Especialidades.css";
import Especialidad from "./Especialidad";

export class Especialidades extends Component {
  state = {
    specialties: [],
    selected: {
      name: ""
    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.specialties) {
      let specialties = {};
      nextProps.specialties.forEach(specialty => {
        specialties[specialty.name] = specialty;
      });

      this.setState({ specialties: specialties });

      let elems = document.querySelectorAll(".autocomplete");

      let data = nextProps.specialties.reduce((acc, specialty) => {
        acc[specialty.name] = null;
        return acc;
      }, {});

      let options = {
        data: data,
        onAutocomplete: selection => {
          this.setState({ selected: this.state.specialties[selection] });
        }
      };

      let instances = M.Autocomplete.init(elems, options);
    }
  }

  componentDidMount() {}

  render() {
    return (
      <div className="edit-especialidades-container">
        <h5>Manejar Especialidades</h5>
        <div className="">
          <div className="row">
            <div className="input-field col s12">
              <i className="material-icons prefix">search</i>
              <input
                type="text"
                id="especialidades-input"
                className="autocomplete"
              />
              <label for="especialidades-input">Especialidades</label>
            </div>
          </div>
        </div>
        <Especialidad {...this.state.selected} />
      </div>
    );
  }
}

export default Especialidades;
