const initState = {
  patients: []
};

const doctorReducer = (state = initState, action) => {
  switch (action.type) {
    case "CREATE_PATIENT":
      console.log("added patient", action.doctor);
      return state;
    case "CREATE_PATIENT_ERROR":
      console.log("error adding patient", action.error);
      return state;
    case "DELETE_PATIENT":
      console.log("removed patient", action.patientID);
      return state;
    case "DELETE_PATIENT_ERROR":
      console.log("error removing patient", action.error);
      return state;
    case "EDIT_PATIENT":
      console.log("edit patient", action.patientID);
      return state;
    case "EDIT_PATIENT_ERROR":
      console.log("error editing patient", action.error);
      return state;
    default:
      return state;
  }
};

export default doctorReducer;
