import React, { Component } from "react";

import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";

import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";

import { changePercentage } from "../../store/actions/appointmentActions";

import "./Admin.css";

export class PercentageSlider extends Component {
  componentWillReceiveProps(props) {
    if (props.percentage)
      this.setState({
        value: props.percentage[0].value,
        newValue: props.percentage[0].value
      });
  }
  constructor(props) {
    super(props);

    this.state = {
      value: 0,
      newValue: 0
    };
  }

  handleChangeStart = () => {
    console.log("Change event started");
  };

  handleChange = value => {
    this.setState({
      newValue: value
    });
  };

  handleChangeComplete = () => {
    if (
      window.confirm(
        `¿Estás seguro de cambiar el porcentaje de ${this.state.value}% a ${
          this.state.newValue
        }% ?`
      )
    ) {
      this.props.changePercentage(this.state.newValue);
    } else {
      this.setState({ newValue: this.state.value });
    }
  };

  render() {
    const horizontalLabels = {
      0: "0%",
      50: "50%",
      100: "100%"
    };

    const formatPercentage = value => `${value}%`;

    return (
      <div className="handle-percentage">
        <Slider
          min={0}
          max={100}
          value={this.state.newValue}
          labels={horizontalLabels}
          format={formatPercentage}
          onChangeStart={this.handleChangeStart}
          onChange={this.handleChange}
          onChangeComplete={this.handleChangeComplete}
        />
        <div className="value">{formatPercentage(this.state.newValue)}</div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  console.log(state);
  return {
    percentage: state.firestore.ordered.percentage
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changePercentage: percentage => {
      dispatch(changePercentage(percentage));
    }
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firestoreConnect([{ collection: "percentage" }])
)(PercentageSlider);
