import React, { Component } from "react";

import { connect } from "react-redux";

import {
  createEspecialidad,
  deleteEspecialidad,
  editEspecialidad
} from "../../store/actions/especialidadesActions";

export class Especialidad extends Component {
  state = {
    name: "",
    id: ""
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.name !== "") {
      let input = document.querySelector("label[for=name]");
      input.classList.add("active");
    }
    this.setState({ name: nextProps.name, id: nextProps.id });
  }

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleSubmit = e => {
    e.preventDefault();

    let especialidad = {
      name: this.state.name
    };

    if (!this.state.id) {
      this.props.createEspecialidad(especialidad);
    } else {
      this.props.editEspecialidad(this.state.id, especialidad);
    }
    this.setState({ name: "", id: undefined });
  };

  handleDelete = e => {
    e.preventDefault();
    if (this.state.id) {
      this.props.deleteEspecialidad(this.state.id);
    }
    this.setState({ name: "", id: undefined });
  };

  render() {
    return (
      <div className="especialidad">
        <form className="row" onSubmit={this.handleSubmit}>
          <div className="input-field col s8">
            <input
              id="name"
              type="text"
              className="validate"
              value={this.state.name}
              onChange={this.handleChange}
            />
            <label htmlFor="name">Nombre</label>
          </div>
          <div className="input-field col s2">
            <button className="btn-small" onClick={this.handleDelete}>
              {" "}
              <i className="material-icons">delete</i>
            </button>
          </div>
          <div className="input-field col s2">
            <button className="btn-small">
              {" "}
              <i className="material-icons">send</i>
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createEspecialidad: especialidad => {
      dispatch(createEspecialidad(especialidad));
    },
    deleteEspecialidad: especialidadID => {
      dispatch(deleteEspecialidad(especialidadID));
    },
    editEspecialidad: (especialidadID, especialidad) => {
      dispatch(editEspecialidad(especialidadID, especialidad));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(Especialidad);
