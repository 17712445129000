import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";
import "firebase/storage";

var config = {
  apiKey: "AIzaSyD79wjf8iaCB78-S1FF1vErohvhEeC6m7E",
  authDomain: "consultorios-1b83c.firebaseapp.com",
  databaseURL: "https://consultorios-1b83c.firebaseio.com",
  projectId: "consultorios-1b83c",
  storageBucket: "consultorios-1b83c.appspot.com",
  messagingSenderId: "889217271510"
};

firebase.initializeApp(config);
firebase.firestore().settings({ timestampsInSnapshots: true });

export default firebase;
