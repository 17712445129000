export const createPatient = patient => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection("patients")
      .add({ ...patient, createdAt: new Date() })
      .then(() => {
        dispatch({
          type: "CREATE_PATIENT",
          patient
        });
      })
      .catch(error => {
        dispatch({
          type: "CREATE_PATIENT_ERROR",
          error
        });
      });
  };
};

export const deletePatient = patientID => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection("patients")
      .doc(patientID)
      .then(() => {
        dispatch({
          type: "DELETE_PATIENT",
          patientID
        });
      })
      .catch(error => {
        dispatch({
          type: "DELETE_PATIENT_ERROR",
          error
        });
      });
  };
};

export const editPatient = (patientID, patient) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection("patients")
      .doc(patientID)
      .set(
        {
          ...patient
        },
        { merge: true }
      )
      .then(() => {
        dispatch({
          type: "EDIT_PATIENT",
          patientID
        });
      })
      .catch(error => {
        dispatch({
          type: "EDIT_PATIENT_ERROR",
          error
        });
      });
  };
};
