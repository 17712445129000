import React from "react";

import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";

import Sidebar from "../dashboard/Sidebar";
import Calendar from "../calendar/Calendar";
import { Redirect } from "react-router-dom";
import Spinner from "react-spinkit";

const mapStateToProps = (state, ownProps) => {
  const newState = {
    id: ownProps.match.params.id,
    doctor: {
      ...state.firestore.data[`doctor-${ownProps.match.params.id}`],
      id: ownProps.match.params.id
    } || { id: ownProps.match.params.id },
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    patients: state.firestore.ordered.patients,
    percentage: state.firestore.ordered.percentage,
    appointments:
      state.firestore.ordered[`appointments-${ownProps.match.params.id}`] || []
  };

  return newState;
};

const d = new Date();
d.setDate(d.getDate() - 30);

export default compose(
  firestoreConnect(props => [
    { collection: "patients" },
    {
      collection: "appointments",
      where: [
        ["doctorID", "==", props.match.params.id],
        ["start", ">=", d]
      ],
      storeAs: `appointments-${props.match.params.id}`
    },
    { collection: "percentage" },
    {
      collection: "doctors",
      doc: props.match.params.id,
      storeAs: `doctor-${props.match.params.id}`
    }
  ]),
  connect(mapStateToProps)
)(function Doctor({
  appointments,
  doctor,
  id,
  history,
  auth,
  patients,
  profile,
  percentage
}) {
  if (!profile.isLoaded) {
    return (
      <div className="spinner-outer-wrapper">
        <div className="spinner-inner-wrapper">
          <Spinner name="ball-scale-ripple" />
          Cargando
        </div>
      </div>
    );
  }

  if (profile.doctor) {
    return <Redirect to={`/doctor/${profile.doctorID}`} />;
  }

  if (!auth.uid) {
    return <Redirect to="/signin" />;
  }

  return (
    <div id={id + "dashboard"} className="dashboard">
      <Sidebar
        appointments={appointments}
        doctor={doctor}
        percentage={percentage ? percentage[0].value : 0}
      />
      <Calendar
        events={appointments}
        doctorID={id}
        doctor={doctor}
        history={history}
        defaultView="week"
        patients={patients}
      />
    </div>
  );
});
