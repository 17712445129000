import React from "react";
import { NavLink } from "react-router-dom";
export default function LoggedOutLinks({ place }) {
  return (
    <div>
      <div>
        <ul className="right">
          <li>
            <NavLink
              to="/signin"
              className={place === "signin" ? "current" : ""}
            >
              Iniciar sesión
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
}
