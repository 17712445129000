export const createDoctor = doctor => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    let doctorID;

    firestore
      .collection("doctors")
      .add({ ...doctor, createdAt: new Date() })
      .then(response => {
        doctorID = response.id;
        return firebase
          .auth()
          .createUserWithEmailAndPassword(doctor.email, doctorID);
      })
      .then(response => {
        return firestore
          .collection("users")
          .doc(response.user.uid)
          .set({
            firstName: doctor.name.split(" ")[0],
            lastName: doctor.name.split(" ")[1],
            doctor: true,
            doctorID,
            admin: false,
            initials:
              doctor.name.split(" ")[0][0] + doctor.name.split(" ")[1][0]
          });
      })
      .then(() => {
        dispatch({
          type: "CREATE_DOCTOR",
          doctor
        });
      })
      .catch(error => {
        dispatch({
          type: "CREATE_DOCTOR_ERROR",
          error
        });
      });
  };
};

export const editDoctor = (doctorID, doctor) => {
  console.log(doctorID, doctor);
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection("doctors")
      .doc(doctorID)
      .set(
        {
          ...doctor
        },
        { merge: true }
      )
      .then(() => {
        dispatch({
          type: "EDIT_DOCTOR",
          doctorID
        });
      })
      .catch(error => {
        dispatch({
          type: "EDIT_DOCTOR_ERROR",
          error
        });
      });
  };
};

export const deleteDoctor = doctorID => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection("doctors")
      .doc(doctorID)
      .delete()
      .then(() => {
        dispatch({
          type: "DELETE_DOCTOR",
          doctorID
        });
      })
      .catch(error => {
        dispatch({
          type: "DELETE_DOCTOR_ERROR",
          error
        });
      });
  };
};
