const initState = {
  doctors: []
};

const doctorReducer = (state = initState, action) => {
  switch (action.type) {
    case "CREATE_DOCTOR":
      console.log("added doctor", action.doctor);
      return state;
    case "CREATE_DOCTOR_ERROR":
      console.log("error adding doctor", action.error);
      return state;
    case "EDIT_DOCTOR":
      console.log("edited doctor", action.doctorID);
      return state;
    case "EDIT_DOCTOR_ERROR":
      console.log("error modifying doctor", action.error);
      return state;
    case "DELETE_DOCTOR":
      console.log("deleted doctor", action.doctorID);
      return state;
    case "DELETE_DOCTOR_ERROR":
      console.log("error deleting doctor", action.error);
      return state;
    default:
      return state;
  }
};

export default doctorReducer;
