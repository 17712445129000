import React, { Component } from "react";

import { Link } from "react-router-dom";

import M from "materialize-css";

import "./AddDoctor.css";

import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { createDoctor } from "../../store/actions/doctorActions";
import { editDoctor } from "../../store/actions/doctorActions";

import Select from "react-select";
import Scrollbar from "react-scrollbars-custom";
import { Redirect } from "react-router-dom";
import Spinner from "react-spinkit";

import Range from "rc-slider/lib/Range";
import "rc-slider/assets/index.css";
import { isString } from "util";

const mapStateToProps = (state, ownState) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    specialties: state.firestore.ordered.specialties,
    doctor: ownState.location.state && ownState.location.state.doctor,
    editing: ownState.location.state && ownState.location.state.editing,
    id: ownState.location.state && ownState.location.state.id
  };
};
const mapDispatchToProps = dispatch => {
  return {
    createDoctor: doctor => {
      dispatch(createDoctor(doctor));
    },
    editDoctor: (doctorID, doctor) => {
      dispatch(editDoctor(doctorID, doctor));
    }
  };
};

class AddDoctor extends Component {
  constructor(props) {
    super(props);

    if (this.props.editing && this.props.doctor) {
      this.state = {
        ...this.props.doctor.businessHours,
        specialties: this.props.doctor.specialties,
        name: this.props.doctor.name,
        email: this.props.doctor.email,
        price: this.props.doctor.price,
        renta: this.props.doctor.renta
      };
    } else {
      this.state = {
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        specialties: [],
        name: "",
        email: "",
        price: "",
        renta: false
      };
    }
  }

  componentDidUpdate() {
    M.updateTextFields();
  }

  getValues = array => {
    return array.map(element => {
      return element.value;
    });
  };

  getSchedule = (dow, array) => {
    let hours = array.map((value, index, array) => {
      if (index !== array.length - 1) return `${value}-${array[index + 1]}`;
      return "last";
    });
    hours = hours.filter((hour, index) => {
      return index % 2 === 0;
    });
    let businessHours = hours.map(entry => {
      return {
        start: entry.split("-")[0],
        end: entry.split("-")[1]
      };
    });

    this.setState({ [dow]: businessHours });
  };

  re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  isEmail = email => {
    if (this.re.test(email)) {
      return true;
    }
  };

  handleRegister = e => {
    e.preventDefault();

    let specialties;

    if (isString(this.state.specialties[0])) {
      specialties = this.state.specialties;
    } else {
      specialties = this.getValues(this.state.specialties);
    }

    let businessHours = {
      1: this.state[1],
      2: this.state[2],
      3: this.state[3],
      4: this.state[4],
      5: this.state[5],
      6: this.state[6]
    };

    let doctor = {
      name: this.state.name,
      email: this.state.email,
      price: parseInt(this.state.price),
      specialties: specialties,
      businessHours: businessHours,
      renta: this.state.renta
    };

    if (this.props.editing) {
      this.props.editDoctor(this.props.id, doctor);
      this.props.history.push("/");
    } else {
      this.props.createDoctor(doctor);
      this.props.history.push("/doctors/");
    }
  };

  handleDataChange = e => {
    let property = e.target.dataset.statetarget;
    this.setState({ [property]: e.target.value });
  };

  handleCheckboxChange = e => {
    let property = e.target.dataset.statetarget;
    this.setState({ [property]: !this.state[property] });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.specialties) {
      this.specialtiesOptions = nextProps.specialties.map(specialty => {
        return { value: specialty.name, label: specialty.name };
      });
    }
  }
  specialtiesOptions = [];

  monToFridayDefaultValue = [10, 12, 14, 16, 18, 20];
  monToFridayMin = 8;
  monToFridayMax = 21;
  monToFridayMarks = {
    8: "8:00",
    9: "9:00",
    10: "10:00",
    11: "11:00",
    12: "12:00",
    13: "13:00",
    14: "14:00",
    15: "15:00",
    16: "16:00",
    17: "17:00",
    18: "18:00",
    19: "19:00",
    20: "20:00",
    21: "21:00"
  };

  saturdayDefaultValue = [9, 10, 11, 12, 13, 14];
  saturdayMin = 8;
  saturdayMax = 15;
  saturdayMarks = {
    8: "8:00",
    9: "9:00",
    10: "10:00",
    11: "11:00",
    12: "12:00",
    13: "13:00",
    14: "14:00",
    15: "15:00"
  };

  handleSelectChange = (key, array) => {
    this.setState({ [key]: array });
  };

  render() {
    if (!this.props.profile.isLoaded) {
      return (
        <div className="spinner-outer-wrapper">
          <div className="spinner-inner-wrapper">
            <Spinner name="ball-scale-ripple" />
            Cargando
          </div>
        </div>
      );
    }

    if (this.props.profile.doctor) {
      return <Redirect to={`/doctor/${this.props.profile.doctorID}`} />;
    }

    if (!this.props.auth.uid) {
      return <Redirect to="/signin" />;
    }

    return (
      <div id="add-doctor-modal" className="modal modal-fixed-footer">
        <Scrollbar className="modal-content row">
          <form>
            <div className="input-field col s10">
              <i className="material-icons prefix">account_circle</i>
              <input
                id="doctor_name"
                type="text"
                className="validate"
                value={this.state.name}
                data-statetarget="name"
                onChange={this.handleDataChange}
              />
              <label for="doctor_name">Nombre</label>
            </div>
            <div className="input-field col s2">
              <label>
                {this.state.renta ? (
                  <input
                    type="checkbox"
                    class="filled-in"
                    checked
                    data-statetarget="renta"
                    onChange={this.handleCheckboxChange}
                  />
                ) : (
                  <input
                    type="checkbox"
                    class="filled-in"
                    data-statetarget="renta"
                    onChange={this.handleCheckboxChange}
                  />
                )}
                <span>Renta</span>
              </label>
            </div>

            <div className="input-field col s8">
              <i className="material-icons prefix">email</i>
              <input
                id="doctor_email"
                type="text"
                className="validate"
                value={this.state.email}
                data-statetarget="email"
                onChange={this.handleDataChange}
              />
              <label for="doctor_email">Correo</label>
            </div>
            <div className="input-field col s4">
              <i className="material-icons prefix">attach_money</i>
              <input
                id="doctor_price"
                type="number"
                className="validate"
                value={this.state.price}
                data-statetarget="price"
                onChange={this.handleDataChange}
              />
              <label for="doctor_price">Precio de consulta</label>
            </div>
            <div className="input-field col s12 has-icon-centered">
              <icon className="material-icons left">healing</icon>
              <Select
                id="doctor_specialties"
                onChange={this.handleSelectChange.bind(this, "specialties")}
                options={this.specialtiesOptions}
                defaultValue={this.state.specialties.map(specialty => {
                  return { value: specialty, label: specialty };
                })}
                isMulti
                className="select-input"
                name="specialties"
                placeholder="Especialidades"
                noOptionsMessage={() => "No hay más opciones"}
                closeMenuOnSelect={false}
              />{" "}
            </div>
            <div className="input-field col s12 has-icon-centered">
              <icon className="material-icons left">schedule</icon>
              <Range
                defaultValue={
                  this.props.editing
                    ? Object.values(this.state["1"]).reduce((array, key) => {
                        array.push(key.start, key.end);
                        return array;
                      }, [])
                    : this.monToFridayDefaultValue
                }
                trackStyle={[
                  { backgroundColor: "var(--heavy-color)" },
                  { backgroundColor: "#e9e9e9" },
                  { backgroundColor: "var(--heavy-color)" },
                  { backgroundColor: "#e9e9e9" },
                  { backgroundColor: "var(--heavy-color)" }
                ]}
                onChange={this.getSchedule.bind(this, 1)}
                count={6}
                min={this.monToFridayMin}
                max={this.monToFridayMax}
                marks={this.monToFridayMarks}
                step={0.5}
              />
            </div>
            <div className="input-field col s12 has-icon-centered">
              <icon className="material-icons left">schedule</icon>
              <Range
                defaultValue={
                  this.props.editing
                    ? Object.values(this.state["2"]).reduce((array, key) => {
                        array.push(key.start, key.end);
                        return array;
                      }, [])
                    : this.monToFridayDefaultValue
                }
                trackStyle={[
                  { backgroundColor: "var(--heavy-color)" },
                  { backgroundColor: "#e9e9e9" },
                  { backgroundColor: "var(--heavy-color)" },
                  { backgroundColor: "#e9e9e9" },
                  { backgroundColor: "var(--heavy-color)" }
                ]}
                onChange={this.getSchedule.bind(this, 2)}
                count={6}
                min={this.monToFridayMin}
                max={this.monToFridayMax}
                marks={this.monToFridayMarks}
                step={0.5}
              />
            </div>
            <div className="input-field col s12 has-icon-centered">
              <icon className="material-icons left">schedule</icon>
              <Range
                defaultValue={
                  this.props.editing
                    ? Object.values(this.state["3"]).reduce((array, key) => {
                        array.push(key.start, key.end);
                        return array;
                      }, [])
                    : this.monToFridayDefaultValue
                }
                trackStyle={[
                  { backgroundColor: "var(--heavy-color)" },
                  { backgroundColor: "#e9e9e9" },
                  { backgroundColor: "var(--heavy-color)" },
                  { backgroundColor: "#e9e9e9" },
                  { backgroundColor: "var(--heavy-color)" }
                ]}
                onChange={this.getSchedule.bind(this, 3)}
                count={6}
                min={this.monToFridayMin}
                max={this.monToFridayMax}
                marks={this.monToFridayMarks}
                step={0.5}
              />
            </div>
            <div className="input-field col s12 has-icon-centered">
              <icon className="material-icons left">schedule</icon>
              <Range
                defaultValue={
                  this.props.editing
                    ? Object.values(this.state["4"]).reduce((array, key) => {
                        array.push(key.start, key.end);
                        return array;
                      }, [])
                    : this.monToFridayDefaultValue
                }
                trackStyle={[
                  { backgroundColor: "var(--heavy-color)" },
                  { backgroundColor: "#e9e9e9" },
                  { backgroundColor: "var(--heavy-color)" },
                  { backgroundColor: "#e9e9e9" },
                  { backgroundColor: "var(--heavy-color)" }
                ]}
                onChange={this.getSchedule.bind(this, 4)}
                count={6}
                min={this.monToFridayMin}
                max={this.monToFridayMax}
                marks={this.monToFridayMarks}
                step={0.5}
              />
            </div>
            <div className="input-field col s12 has-icon-centered">
              <icon className="material-icons left">schedule</icon>
              <Range
                defaultValue={
                  this.props.editing
                    ? Object.values(this.state["5"]).reduce((array, key) => {
                        array.push(key.start, key.end);
                        return array;
                      }, [])
                    : this.monToFridayDefaultValue
                }
                trackStyle={[
                  { backgroundColor: "var(--heavy-color)" },
                  { backgroundColor: "#e9e9e9" },
                  { backgroundColor: "var(--heavy-color)" },
                  { backgroundColor: "#e9e9e9" },
                  { backgroundColor: "var(--heavy-color)" }
                ]}
                onChange={this.getSchedule.bind(this, 5)}
                count={6}
                min={this.monToFridayMin}
                max={this.monToFridayMax}
                marks={this.monToFridayMarks}
                step={0.5}
              />
            </div>
            <div className="input-field col s12 has-icon-centered">
              <icon className="material-icons left">schedule</icon>
              <Range
                defaultValue={
                  this.props.editing
                    ? Object.values(this.state["6"]).reduce((array, key) => {
                        array.push(key.start, key.end);
                        return array;
                      }, [])
                    : this.saturdayDefaultValue
                }
                trackStyle={[
                  { backgroundColor: "var(--heavy-color)" },
                  { backgroundColor: "#e9e9e9" },
                  { backgroundColor: "var(--heavy-color)" },
                  { backgroundColor: "#e9e9e9" },
                  { backgroundColor: "var(--heavy-color)" }
                ]}
                onChange={this.getSchedule.bind(this, 6)}
                count={6}
                min={this.saturdayMin}
                max={this.saturdayMax}
                marks={this.saturdayMarks}
                step={0.5}
              />
            </div>
          </form>
        </Scrollbar>
        <div className="modal-footer">
          <Link
            to="/doctors"
            className="modal-close btn-small btn-flat btn"
            id="add-doctor-cancel-button"
          >
            Cancelar
          </Link>
          <Link
            to="/"
            id="add-doctor-button"
            className="waves-effect waves-blue btn-small btn"
            onClick={this.handleRegister}
          >
            {this.props.editing ? (
              <span>Editar</span>
            ) : (
              <span>
                Agregar<i className="material-icons right">person_add</i>
              </span>
            )}
          </Link>
        </div>
      </div>
    );
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firestoreConnect([{ collection: "specialties" }])
)(AddDoctor);
