import React, { Component } from "react";

import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";

import { Redirect } from "react-router-dom";

import "./Directorio.css";

import Spinner from "react-spinkit";
import Paciente from "./Paciente";

export class Directorio extends Component {
  render() {
    let patients = this.props.patients
      ? this.props.patients.sort((a, b) => {
          return a.name > b.name;
        })
      : [];

    if (!this.props.profile.isLoaded) {
      return (
        <div className="spinner-outer-wrapper">
          <div className="spinner-inner-wrapper">
            <Spinner name="ball-scale-ripple" />
            Cargando
          </div>
        </div>
      );
    }

    if (this.props.profile.doctor) {
      return <Redirect to={`/doctor/${this.props.profile.doctorID}`} />;
    }

    if (!this.props.auth.uid) {
      return <Redirect to="/signin" />;
    }

    if (patients) {
      return (
        <div className="patients-dashboard">
          <table className="responsive-table highlight">
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Correo</th>
                <th>Teléfono</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {patients.map(patient => {
                if (patient.name === "") {
                  return <div />;
                }
                return <Paciente {...patient} />;
              })}
            </tbody>
          </table>
        </div>
      );
    }

    return <div />;
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    patients: state.firestore.ordered.patients
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect([{ collection: "patients" }])
)(Directorio);
