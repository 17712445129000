import React, { Component } from "react";

import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";

import { Redirect } from "react-router-dom";

import Spinner from "react-spinkit";

import "./Admin.css";
import Especialidades from "../especialidades/Especialidades";

import PercentageSlider from "./PercentageSlider";
import ReporteGenerator from "../reportes/ReporteGenerator";

export class Admin extends Component {
  render() {
    if (!this.props.profile.isLoaded) {
      return (
        <div className="spinner-outer-wrapper">
          <div className="spinner-inner-wrapper">
            <Spinner name="ball-scale-ripple" />
            Cargando
          </div>
        </div>
      );
    }

    if (this.props.profile.doctor) {
      return <Redirect to={`/doctor/${this.props.profile.doctorID}`} />;
    }

    if (!this.props.profile.admin) {
      return <Redirect to="/" />;
    }

    if (!this.props.auth.uid) {
      return <Redirect to="/signin" />;
    }

    return (
      <div id="admin-dashboard" className="dashboard">
        <div className="sidebar">
          <Especialidades specialties={this.props.specialties} />
          <div className="sidebar-general-info">
            <h5>Manejar porcentaje</h5>
            <PercentageSlider />
          </div>
        </div>
        <ReporteGenerator />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    specialties: state.firestore.ordered.specialties
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect([
    { collection: "patients" },
    { collection: "appointments" },
    { collection: "specialties" }
  ])
)(Admin);
