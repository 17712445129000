import React from "react";

import { editAppointment } from "../../store/actions/appointmentActions";
import { connect } from "react-redux";

function IndividualAppointment(props) {
  let { appointment, getHour, editAppointment, individual } = props;
  let {
    id,
    title,
    doctor,
    patient,
    start,
    end,
    arrived,
    absent,
    inProgress
  } = appointment;

  const handleClick = e => {
    e.preventDefault();
    const property = e.target.dataset.property;

    let appointment = {};

    switch (property) {
      case "arrived":
        appointment = {
          absent: false,
          inProgress: false
        };
        break;
      case "absent":
        appointment = {
          arrived: false,
          inProgress: false
        };
        break;
      case "inProgress":
        appointment = {
          absent: false,
          arrived: false
        };
        break;
      default:
        break;
    }

    appointment[property] = true;

    editAppointment(id, appointment);
  };

  return (
    <div
      className="sidebar-individual-appointment"
      key={"left-appointment" + "-" + id}
    >
      <div className="sidebar-individual-appointment-buttons">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
          className={arrived ? "selected" : individual ? "no-hover" : ""}
          onClick={!individual ? handleClick : () => {}}
          data-property="arrived"
        >
          <path
            data-property="arrived"
            className="heroicon-ui"
            d="M17.62 10H20a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H8.5c-1.2 0-2.3-.72-2.74-1.79l-3.5-7-.03-.06A3 3 0 0 1 5 9h5V4c0-1.1.9-2 2-2h1.62l4 8zM16 11.24L12.38 4H12v7H5a1 1 0 0 0-.93 1.36l3.5 7.02a1 1 0 0 0 .93.62H16v-8.76zm2 .76v8h2v-8h-2z"
          />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
          className={absent ? "selected" : individual ? "no-hover" : ""}
          onClick={!individual ? handleClick : () => {}}
          data-property="absent"
        >
          <path
            data-property="absent"
            className="heroicon-ui"
            d="M6.38 14H4a2 2 0 0 1-2-2V4c0-1.1.9-2 2-2h11.5c1.2 0 2.3.72 2.74 1.79l3.5 7 .03.06A3 3 0 0 1 19 15h-5v5a2 2 0 0 1-2 2h-1.62l-4-8zM8 12.76L11.62 20H12v-7h7c.13 0 .25-.02.38-.08a1 1 0 0 0 .55-1.28l-3.5-7.02A1 1 0 0 0 15.5 4H8v8.76zM6 12V4H4v8h2z"
          />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
          className={inProgress ? "selected" : individual ? "no-hover" : ""}
          onClick={!individual ? handleClick : () => {}}
          data-property="inProgress"
        >
          <path
            data-property="inProgress"
            className="heroicon-ui"
            d="M17.56 17.66a8 8 0 0 1-11.32 0L1.3 12.7a1 1 0 0 1 0-1.42l4.95-4.95a8 8 0 0 1 11.32 0l4.95 4.95a1 1 0 0 1 0 1.42l-4.95 4.95zm-9.9-1.42a6 6 0 0 0 8.48 0L20.38 12l-4.24-4.24a6 6 0 0 0-8.48 0L3.4 12l4.25 4.24zM11.9 16a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
          />
        </svg>
      </div>
      <span className="sidebar-individual-appointment-details">
        <span>
          <h6 className="sidebar-individual-appointment-details-name">
            {title}
          </h6>
          <p className="sidebar-individual-appointment-patient">
            Dr. {doctor.name}
          </p>
          <p className="sidebar-individual-appointment-patient">
            {patient.name}
          </p>
        </span>
        <span className="sidebar-individual-appointment-details-time">
          {start instanceof Date
            ? getHour(start) + " - " + getHour(end)
            : getHour(start.toDate()) + " - " + getHour(end.toDate())}
        </span>
      </span>
    </div>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    editAppointment: (appointmentID, appointment) => {
      dispatch(editAppointment(appointmentID, appointment));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(IndividualAppointment);
