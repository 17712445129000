import React from "react";
import { NavLink } from "react-router-dom";

import { connect } from "react-redux";
import { signOut } from "../../store/actions/authActions";

const mapDispatchToProps = dispatch => {
  return {
    signOut: e => {
      e.preventDefault();
      dispatch(signOut());
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(function authActions({ signOut, profile, place }) {
  if (profile.isLoaded && profile.doctor) {
    return (
      <div>
        <ul className="right">
          <li>
            <a href="/#" onClick={signOut}>
              Cerrar sesión
            </a>
          </li>
          <li>
            <NavLink to="/" className="btn btn-floating pink lighten-1">
              {profile.initials}
            </NavLink>
          </li>
        </ul>
      </div>
    );
  }

  if (profile.isLoaded && profile.admin) {
    return (
      <div>
        <ul className="right">
          <li>
            <NavLink
              to="/patients"
              className={place === "patients" ? "current" : ""}
            >
              Pacientes
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/doctors"
              className={place === "doctors" || place === "" ? "current" : ""}
            >
              Doctores
            </NavLink>
          </li>
          <li>
            <NavLink to="/admin" className={place === "admin" ? "current" : ""}>
              Admin
            </NavLink>
          </li>
          <li>
            <a href="/#" onClick={signOut}>
              Cerrar sesión
            </a>
          </li>
          <li>
            <NavLink to="/" className="btn btn-floating pink lighten-1">
              {profile.initials}
            </NavLink>
          </li>
        </ul>
      </div>
    );
  }
  
  return (
    <div>
      <ul className="right">
        <li>
          <NavLink
            to="/patients"
            className={place === "patients" ? "current" : ""}
          >
            Pacientes
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/doctors"
            className={place === "doctors" || place === "" ? "current" : ""}
          >
            Doctores
          </NavLink>
        </li>
        <li>
          <a onClick={signOut}>Cerrar sesión</a>
        </li>
        <li>
          <NavLink to="/" className="btn btn-floating pink lighten-1">
            {profile.initials}
          </NavLink>
        </li>
      </ul>
    </div>
  );
});
