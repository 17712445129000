import React from "react";

import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";

import Sidebar from "../dashboard/Sidebar";
import Calendar from "../calendar/Calendar";
import { Redirect } from "react-router-dom";
import Spinner from "react-spinkit";

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const doctors = state.firestore.data.doctors;
  const doctor = doctors ? doctors[id] : null;
  const newState = {
    id: id,
    doctor: doctor,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    patients: state.firestore.ordered.patients,
    percentage: state.firestore.ordered.percentage
  };

  if (state.firestore.ordered.appointments) {
    state.firestore.ordered.appointments.filter(appointment => {
      return appointment.doctorID === id;
    });

    newState["appointments"] = state.firestore.ordered.appointments.filter(
      appointment => {
        return appointment.doctorID === id;
      }
    );
  }

  return newState;
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect([
    { collection: "patients" },
    { collection: "appointments" },
    { collection: "percentage" },
    { collection: "doctors" }
  ])
)(function Doctor({
  appointments,
  doctor,
  id,
  history,
  auth,
  patients,
  profile,
  percentage
}) {
  if (!profile.isLoaded) {
    return (
      <div className="spinner-outer-wrapper">
        <div className="spinner-inner-wrapper">
          <Spinner name="ball-scale-ripple" />
          Cargando
        </div>
      </div>
    );
  }

  if (!profile.doctor) {
    return <Redirect to="/doctors" />;
  }

  if (!auth.uid) {
    return <Redirect to="/signin" />;
  }

  return (
    <div id={id + "dashboard"} className="dashboard">
      <Sidebar
        individual={true}
        appointments={appointments}
        doctor={doctor}
        percentage={percentage ? percentage[0].value : 0}
      />
      <Calendar
        individual={true}
        events={appointments}
        doctorID={id}
        doctor={doctor}
        history={history}
        defaultView="week"
        patients={patients}
      />
    </div>
  );
});
