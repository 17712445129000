import React, { Component } from "react";

import M from "materialize-css";

import { Link } from "react-router-dom";
import Scrollbar from "react-scrollbars-custom";

import "./AddAppointment.css";
import "../doctor/AddDoctor.css";

export default class AddAppointment extends Component {
  componentDidUpdate() {
    M.updateTextFields();
  }

  render() {
    let {
      editing,
      edit,
      start,
      end,
      currentTitle,
      consultorio,
      handleDataChange,
      patientMail,
      patientName,
      patientPhone,
      close,
      add,
      handlePatientLookup
    } = this.props;

    let getHour = date => {
      let hours = date.getHours();
      let minutes = date.getMinutes();
      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      return hours + ":" + minutes;
    };

    let startText = getHour(start);
    let endText = getHour(end);

    if (!editing) {
      return (
        <div id="add-appointment-modal" className="modal modal-fixed-footer">
          <Scrollbar className="modal-content row">
            <form className="col s12">
              <div className="input-field col s12">
                <input
                  id="appointment_name"
                  type="text"
                  className="validate"
                  data-statetarget="currentTitle"
                  value={currentTitle}
                  onChange={handleDataChange}
                  required
                  autoFocus
                />
                <label id="appointment_label" htmlFor="appointment_name">
                  Título de la cita
                </label>
                <span
                  className="helper-text"
                  data-error="Se necesita ingresar un título"
                  data-success="Correcto"
                >
                  Ingresa un título
                </span>
              </div>
              <div className="has-text-left input-field col s12">
                <span>
                  De <strong>{startText}</strong> a <strong>{endText}</strong>{" "}
                  en consultorio
                </span>
                <input
                  id="appointment_consultorio"
                  type="number"
                  className="validate"
                  data-statetarget="consultorio"
                  value={consultorio}
                  onChange={handleDataChange}
                  required
                />
              </div>
              <div className="input-field col s12">
                <i className="material-icons prefix">phone</i>
                <input
                  id="patient_phone"
                  type="text"
                  className="validate"
                  value={patientPhone}
                  data-statetarget="patientPhone"
                  onChange={handleDataChange}
                  onBlur={handlePatientLookup}
                  required
                />
                <label htmlFor="patient_phone">Teléfono</label>
                <span
                  className="helper-text"
                  data-error="Se necesita ingresar un teléfono válido"
                  data-success="Correcto"
                >
                  Ingresa el teléfono del paciente
                </span>
              </div>
              <div className="input-field col s12">
                <i className="material-icons prefix">person</i>
                <input
                  id="patient_name"
                  type="text"
                  className="validate"
                  value={patientName}
                  data-statetarget="patientName"
                  onChange={handleDataChange}
                  required
                />
                <label htmlFor="patient_name">Nombre</label>
                <span
                  className="helper-text"
                  data-error="Se necesita ingresar un nombre válido"
                  data-success="Correcto"
                >
                  Ingresa el nombre del paciente
                </span>
              </div>
              <div className="input-field col s12">
                <i className="material-icons prefix">email</i>
                <input
                  id="patient_email"
                  type="text"
                  className="validate"
                  value={patientMail}
                  data-statetarget="patientMail"
                  onChange={handleDataChange}
                  required
                />
                <label htmlFor="patient_email">Correo</label>
                <span
                  className="helper-text"
                  data-error="Se necesita ingresar un correo válido"
                  data-success="Correcto"
                >
                  Ingresa el correo del paciente
                </span>
              </div>
            </form>
          </Scrollbar>
          <div className="modal-footer">
            <Link
              to=""
              onClick={close}
              id="add-appointment-cancel-button"
              className="modal-close btn-small btn-flat btn"
            >
              Cancelar
            </Link>
            <Link
              to=""
              id="add-appointment-button"
              className="waves-effect waves-blue btn-small btn"
              onClick={add}
            >
              Crear cita
            </Link>
          </div>
        </div>
      );
    }

    return (
      <div id="add-appointment-modal" className="modal modal-fixed-footer">
        <Scrollbar className="modal-content row">
          <form className="col s12">
            <div className="input-field col s12">
              <input
                id="appointment_name"
                type="text"
                className="validate"
                data-statetarget="currentTitle"
                value={currentTitle}
                onChange={handleDataChange}
                required
                autoFocus
              />
              <label id="appointment_label" htmlFor="appointment_name">
                Título de la cita
              </label>
              <span
                className="helper-text"
                data-error="Se necesita ingresar un título"
                data-success="Correcto"
              >
                Ingresa un título
              </span>
            </div>
            <div className="has-text-left input-field col s12">
              <span>
                De <strong>{startText}</strong> a <strong>{endText}</strong> en
                consultorio
              </span>
              <input
                id="appointment_consultorio"
                type="number"
                className="validate"
                data-statetarget="consultorio"
                value={consultorio}
                onChange={handleDataChange}
                required
              />
            </div>
            <div className="input-field col s12">
              <i className="material-icons prefix">phone</i>
              <input
                id="patient_phone"
                type="text"
                className="validate"
                value={patientPhone}
                data-statetarget="patientPhone"
                onChange={handleDataChange}
                onBlur={handlePatientLookup}
                required
              />
              <label htmlFor="patient_phone">Teléfono</label>
              <span
                className="helper-text"
                data-error="Se necesita ingresar un teléfono válido"
                data-success="Correcto"
              >
                Ingresa el teléfono del paciente
              </span>
            </div>
            <div className="input-field col s12">
              <i className="material-icons prefix">person</i>
              <input
                id="patient_name"
                type="text"
                className="validate"
                value={patientName}
                data-statetarget="patientName"
                onChange={handleDataChange}
                required
              />
              <label htmlFor="patient_name">Nombre</label>
              <span
                className="helper-text"
                data-error="Se necesita ingresar un nombre válido"
                data-success="Correcto"
              >
                Ingresa el nombre del paciente
              </span>
            </div>
            <div className="input-field col s12">
              <i className="material-icons prefix">email</i>
              <input
                id="patient_email"
                type="text"
                className="validate"
                value={patientMail}
                data-statetarget="patientMail"
                onChange={handleDataChange}
                required
              />
              <label htmlFor="patient_email">Correo</label>
              <span
                className="helper-text"
                data-error="Se necesita ingresar un correo válido"
                data-success="Correcto"
              >
                Ingresa el correo del paciente
              </span>
            </div>
          </form>
        </Scrollbar>
        <div className="modal-footer">
          <Link
            to=""
            onClick={close}
            id="add-appointment-cancel-button"
            className="modal-close btn-small btn-flat btn"
          >
            Cancelar
          </Link>
          <Link
            to=""
            id="add-appointment-button"
            className="waves-effect waves-blue btn-small btn"
            onClick={edit}
          >
            Editar cita
          </Link>
        </div>
      </div>
    );
  }
}
