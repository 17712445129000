import React, { Component } from "react";

import "./Dashboard.css";

import Sidebar from "./Sidebar";
import Calendar from "../calendar/Calendar";

import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { Redirect } from "react-router-dom";

import Spinner from "react-spinkit";

const mapStateToProps = state => {
  return {
    appointments: state.firestore.ordered.appointments || [],
    doctors: state.firestore.data.doctors,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    patients: state.firestore.ordered.patients,
    percentage: state.firestore.ordered.percentage
  };
};

const d = new Date();
d.setDate(d.getDate() - 30);

export default compose(
  firestoreConnect([
    {
      collection: "appointments",
      where: ["start", ">=", d]
    },
    { collection: "doctors" },
    { collection: "patients" },
    { collection: "percentage" }
  ]),
  connect(mapStateToProps)
)(
  class Dashboard extends Component {
    render() {
      if (!this.props.profile.isLoaded) {
        return (
          <div className="spinner-outer-wrapper">
            <div className="spinner-inner-wrapper">
              <Spinner name="ball-scale-ripple" />
              Cargando
            </div>
          </div>
        );
      }

      if (this.props.profile.doctor) {
        return <Redirect to={`/doctor/${this.props.profile.doctorID}`} />;
      }

      if (!this.props.auth.uid) {
        return <Redirect to="/signin" />;
      }

      return (
        <div id="dashboard" className="dashboard">
          <Sidebar
            appointments={this.props.appointments}
            doctors={this.props.doctors}
            percentage={
              this.props.percentage
                ? this.props.percentage[0]
                  ? this.props.percentage[0].value
                  : 0
                : 0
            }
          />
          <Calendar
            events={this.props.appointments}
            history={this.props.history}
            patients={this.props.patients}
          />
        </div>
      );
    }
  }
);
