import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import logo from "../../images/logo.png";

import "./Navbar.css";

import LoggedInLinks from "./LoggedInLinks";
import LoggedOutLinks from "./LoggedOutLinks";

const mapStateToProps = (state, ownState) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    place: ownState.location.pathname.split("/")[1]
  };
};

export default connect(mapStateToProps)(({ auth, profile, place }) => {
  return (
    <nav className="nav-wrapper">
      <Link to="/" className="brand-logo">
        <img src={logo} alt="Logo" />
      </Link>
      <div className="nav-links">
        {auth.uid ? (
          <LoggedInLinks profile={profile} place={place} />
        ) : (
          <LoggedOutLinks place={place} />
        )}
      </div>
    </nav>
  );
});
