export const createEspecialidad = especialidad => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection("specialties")
      .add({ ...especialidad, createdAt: new Date() })
      .then(() => {
        dispatch({
          type: "CREATE_ESPECIALIDAD",
          especialidad
        });
      })
      .catch(error => {
        dispatch({
          type: "CREATE_ESPECIALIDAD_ERROR",
          error
        });
      });
  };
};

export const deleteEspecialidad = especialidadID => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection("specialties")
      .doc(especialidadID)
      .delete()
      .then(() => {
        dispatch({
          type: "DELETE_ESPECIALIDAD",
          especialidadID
        });
      })
      .catch(error => {
        dispatch({
          type: "DELETE_ESPECIALIDAD_ERROR",
          error
        });
      });
  };
};

export const editEspecialidad = (especialidadID, especialidad) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection("specialties")
      .doc(especialidadID)
      .set(
        {
          ...especialidad
        },
        { merge: true }
      )
      .then(() => {
        dispatch({
          type: "EDIT_ESPECIALIDAD",
          especialidadID
        });
      })
      .catch(error => {
        dispatch({
          type: "EDIT_ESPECIALIDAD_ERROR",
          error
        });
      });
  };
};
