import React from "react";

import "./Sidebar.css";

import IndividualAppointment from "./IndividualAppointment";

export default function Sidebar({
  appointments,
  doctor,
  doctors,
  percentage,
  individual
}) {
  let getHour = date => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    return hours + ":" + minutes;
  };

  let showMoney = e => {
    e.preventDefault();
    if (
      Array.from(e.target.classList).includes(
        "sidebar-general-info-money-hide-label"
      )
    ) {
      e.target.nextSibling.classList.toggle("show");
      e.target.classList.toggle("show");
      e.target.parentNode.classList.toggle("background");
    } else if (
      Array.from(e.target.classList).includes(
        "sidebar-general-info-money-made-number"
      ) ||
      Array.from(e.target.classList).includes(
        "sidebar-general-info-money-give-number"
      )
    ) {
      e.target.classList.toggle("show");
      e.target.previousSibling.classList.toggle("show");
      e.target.parentNode.classList.toggle("background");
    } else {
      Array.from(e.target.childNodes).forEach(node => {
        node.classList.toggle("show");
      });
      e.target.classList.toggle("background");
    }
  };

  let todayAppointments = [];

  if (appointments) {
    todayAppointments = appointments.filter(appointment => {
      if (!appointment.start || !appointment.end) {
        console.log(appointment.id);
        return false;
      }

      if (!appointment.slots) {
        console.log(appointment.id);
        return false;
      }

      if (appointment.start instanceof Date) {
        return (
          appointment.start.getDate() === new Date().getDate() &&
          appointment.start.getMonth() === new Date().getMonth() &&
          new Date().getFullYear() === appointment.start.getFullYear()
        );
      }
      return (
        appointment.start.toDate().getDate() === new Date().getDate() &&
        appointment.start.toDate().getMonth() === new Date().getMonth() &&
        appointment.start.toDate().getFullYear() === new Date().getFullYear()
      );
    });
  }

  let moneyMade = "0.00";
  let moneyGiven = "0.00";
  let moneyMadeTarjeta = "0.00";
  let moneyGivenTarjeta = "0.00";

  if (doctor && todayAppointments.length > 0) {
    moneyMade = todayAppointments.reduce((total, appointment) => {
      if (appointment.finished && appointment.type === "efectivo") {
        if (!doctor.renta) {
          if (parseFloat(appointment.charged) > parseFloat(doctor.price)) {
            total += parseFloat(doctor.price) * (percentage / 100);
          } else {
            total += parseFloat(appointment.charged) * (percentage / 100);
          }
        }
      }
      return total;
    }, 0);

    moneyMadeTarjeta = todayAppointments.reduce((total, appointment) => {
      if (appointment.finished && appointment.type === "tarjeta") {
        if (!doctor.renta) {
          if (parseFloat(appointment.charged) > parseFloat(doctor.price)) {
            total += parseFloat(doctor.price) * (percentage / 100);
          } else {
            total += parseFloat(appointment.charged) * (percentage / 100);
          }
        }
      }
      return total;
    }, 0);

    moneyGiven = todayAppointments.reduce((total, appointment) => {
      if (appointment.finished && appointment.type === "efectivo") {
        if (!doctor.renta) {
          if (parseFloat(appointment.charged) > parseFloat(doctor.price)) {
            total +=
              parseFloat(appointment.charged) -
              parseFloat(doctor.price) * (percentage / 100);
          } else {
            total += parseFloat(appointment.charged) * (1 - percentage / 100);
          }
        } else {
          total += parseFloat(appointment.charged);
        }
      }
      return total;
    }, 0);

    moneyGivenTarjeta = todayAppointments.reduce((total, appointment) => {
      if (appointment.finished && appointment.type === "tarjeta") {
        if (!doctor.renta) {
          if (parseFloat(appointment.charged) > parseFloat(doctor.price)) {
            total +=
              parseFloat(appointment.charged) -
              parseFloat(doctor.price) * (percentage / 100);
          } else {
            total += parseFloat(appointment.charged) * (1 - percentage / 100);
          }
        } else {
          total += parseFloat(appointment.charged);
        }
      }
      return total;
    }, 0);
  } else if (todayAppointments.length > 0) {
    /* Calculo para cada doctor */

    if (doctors) {
      moneyMade = todayAppointments.reduce((total, appointment) => {
        if (appointment.finished && appointment.type === "efectivo") {
          if (!appointment.doctor.renta) {
            if (
              parseFloat(appointment.charged) >
              parseFloat(appointment.doctor.price)
            ) {
              total +=
                parseFloat(appointment.doctor.price) * (percentage / 100);
            } else {
              total += parseFloat(appointment.charged) * (percentage / 100);
            }
          }
        }
        return total;
      }, 0);

      moneyMadeTarjeta = todayAppointments.reduce((total, appointment) => {
        if (appointment.finished && appointment.type === "tarjeta") {
          if (!appointment.doctor.renta) {
            if (
              parseFloat(appointment.charged) >
              parseFloat(appointment.doctor.price)
            ) {
              total +=
                parseFloat(appointment.doctor.price) * (percentage / 100);
            } else {
              total += parseFloat(appointment.charged) * (percentage / 100);
            }
          }
        }
        return total;
      }, 0);

      moneyGiven = todayAppointments.reduce((total, appointment) => {
        if (appointment.finished && appointment.type === "efectivo") {
          if (!appointment.doctor.renta) {
            if (
              parseFloat(appointment.charged) >
              parseFloat(appointment.doctor.price)
            ) {
              total +=
                parseFloat(appointment.charged) -
                parseFloat(appointment.doctor.price) * (percentage / 100);
            } else {
              total += parseFloat(appointment.charged) * (1 - percentage / 100);
            }
          } else {
            total += parseFloat(appointment.charged);
          }
        }
        return total;
      }, 0);

      moneyGivenTarjeta = todayAppointments.reduce((total, appointment) => {
        if (appointment.finished && appointment.type === "tarjeta") {
          if (!appointment.doctor.renta) {
            if (
              parseFloat(appointment.charged) >
              parseFloat(appointment.doctor.price)
            ) {
              total +=
                parseFloat(appointment.charged) -
                parseFloat(appointment.doctor.price) * (percentage / 100);
            } else {
              total += parseFloat(appointment.charged) * (1 - percentage / 100);
            }
          } else {
            total += parseFloat(appointment.charged);
          }
        }
        return total;
      }, 0);
    }
  }

  moneyMade = parseFloat(Math.round(moneyMade * 100) / 100).toFixed(2);
  moneyGiven = parseFloat(Math.round(moneyGiven * 100) / 100).toFixed(2);
  moneyMadeTarjeta = parseFloat(
    Math.round(moneyMadeTarjeta * 100) / 100
  ).toFixed(2);
  moneyGivenTarjeta = parseFloat(
    Math.round(moneyGivenTarjeta * 100) / 100
  ).toFixed(2);

  let nextAppointments = [];
  let completedAppointments = 0;
  let totalAppointments = 0;

  if (todayAppointments.length > 0) {
    /* Calcular citas completadas */
    completedAppointments = todayAppointments.reduce(
      (completedAppointments, appointment) => {
        if (appointment.finished) {
          completedAppointments += 1;
        }
        return completedAppointments;
      },
      0
    );
    /* Calcular total de citas */
    totalAppointments = todayAppointments.length;

    /* Calcular las citas no completadas del día de hoy */
    let notCompletedAppointments = todayAppointments.filter(appointment => {
      return !appointment.finished;
    });

    /* Ordenarlas de menor a mayor fecha */
    nextAppointments = notCompletedAppointments.sort((a, b) => {
      if (a.start instanceof Date) {
        if (b.start instanceof Date) {
          return a.start - b.start;
        }

        return a.start - b.start.toDate();
      } else {
        if (b.start instanceof Date) {
          return a.start.toDate() - b.start;
        }

        return a.start.toDate() - b.start.toDate();
      }
    });

    /* Reducir el tamaño a 5 como máximo */
    if (nextAppointments.length > 5) {
      nextAppointments.length = 5;
    }
  }

  let options = {
    year: "numeric",
    month: "long",
    day: "numeric"
  };

  if (individual) {
    return (
      <div className="sidebar">
        <div className="sidebar-general-info">
          <h5>
            {doctor
              ? "Doctor " + doctor.name
              : "Especialidades Médicas Tepepan"}
          </h5>
          <div className="sidebar-general-info-appointments">
            <span className="sidebar-general-info-appointments-completed">
              {completedAppointments}
            </span>{" "}
            /{" "}
            <span className="sidebar-general-info-appointments-total">
              {totalAppointments}
            </span>{" "}
            citas completadas
          </div>
        </div>
        <div className="sidebar-next-appointments">
          <h5>
            {"Siguientes citas del " +
              new Date().toLocaleDateString("es-MX", options)}
          </h5>
          {nextAppointments.length > 0 ? (
            nextAppointments.map(appointment => {
              return (
                <IndividualAppointment
                  individual={individual}
                  appointment={appointment}
                  getHour={getHour}
                />
              );
            })
          ) : (
            <div className="sidebar-no-dates-message">
              😮 No hay citas restantes el día de hoy
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="sidebar">
      <div className="sidebar-general-info">
        <h5>
          {doctor ? "Doctor " + doctor.name : "Especialidades Médicas Tepepan"}
        </h5>
        <div className="sidebar-general-info-appointments">
          <span className="sidebar-general-info-appointments-completed">
            {completedAppointments}
          </span>{" "}
          /{" "}
          <span className="sidebar-general-info-appointments-total">
            {totalAppointments}
          </span>{" "}
          citas completadas
        </div>
        <div className="sidebar-general-info-money">
          <span className="sidebar-general-info-money-made">
            <span>Ingresos del consultorio (efectivo):</span>
            <span
              className="sidebar-general-info-money-hide background"
              onClick={showMoney}
            >
              <span className="sidebar-general-info-money-hide-label show">
                Mostrar
              </span>
              <span className="sidebar-general-info-money-made-number">
                {" "}
                $ {moneyMade}
              </span>
            </span>
          </span>
          <span className="sidebar-general-info-money-made">
            <span>Ingresos del consultorio (tarjeta):</span>
            <span
              className="sidebar-general-info-money-hide background"
              onClick={showMoney}
            >
              <span className="sidebar-general-info-money-hide-label show">
                Mostrar
              </span>
              <span className="sidebar-general-info-money-made-number">
                {" "}
                $ {moneyMadeTarjeta}
              </span>
            </span>
          </span>
          <span className="sidebar-general-info-money-give">
            <span>
              {doctor
                ? "Ingresos del doctor (efectivo):"
                : "Ingresos de los doctores (efectivo):"}
            </span>
            <span
              className="sidebar-general-info-money-hide background"
              onClick={showMoney}
            >
              <span className="sidebar-general-info-money-hide-label show">
                Mostrar
              </span>
              <span className="sidebar-general-info-money-give-number">
                {" "}
                $ {moneyGiven}
              </span>
            </span>
          </span>{" "}
          <span className="sidebar-general-info-money-give">
            <span>
              {doctor
                ? "Ingresos del doctor (tarjeta):"
                : "Ingresos de los doctores (tarjeta):"}
            </span>
            <span
              className="sidebar-general-info-money-hide background"
              onClick={showMoney}
            >
              <span className="sidebar-general-info-money-hide-label show">
                Mostrar
              </span>
              <span className="sidebar-general-info-money-give-number">
                {" "}
                $ {moneyGivenTarjeta}
              </span>
            </span>
          </span>{" "}
        </div>
      </div>
      <div className="sidebar-next-appointments">
        <h5>
          {"Siguientes citas del " +
            new Date().toLocaleDateString("es-MX", options)}
        </h5>
        {nextAppointments.length > 0 ? (
          nextAppointments.map(appointment => {
            return (
              <IndividualAppointment
                appointment={appointment}
                getHour={getHour}
              />
            );
          })
        ) : (
          <div className="sidebar-no-dates-message">
            😮 No hay citas restantes el día de hoy
          </div>
        )}
      </div>
    </div>
  );
}
