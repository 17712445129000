import React, { Component } from "react";

import converter from "number-to-words";

import M from "materialize-css";

import BigCalendar from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";

import "react-big-calendar/lib/addons/dragAndDrop/styles.css";

import DoctorPicker from "../doctor/DoctorPicker";
import AddAppointment from "../appointment/AddAppointment";

import {
  createAppointment,
  deleteAppointment,
  editAppointment
} from "../../store/actions/appointmentActions";

import { createPatient } from "../../store/actions/patientActions";
import { connect } from "react-redux";

import moment from "moment";
import "moment/locale/es-us";

import momentTimeZone from "moment-timezone";

import "react-big-calendar/lib/css/react-big-calendar.css";
import "./Calendar.css";

import EditAppointment from "../appointment/EditAppointment";

moment.locale("es-us");

const DragAndDropCalendar = withDragAndDrop(BigCalendar);

let customEvent = ({
  individual,
  deleteAppointment,
  editAppointment,
  chargeAppointment,
  confirmAppointment
}) => {
  return class EventComponent extends Component {
    handleClose = e => {
      let element = document.querySelector(".rbc-selected");
      element.classList.remove(
        "rbc-selected",
        "trigger-enter",
        "trigger-enter-active"
      );
    };

    handleDelete = e => {
      if (
        !this.props.event.finished &&
        window.confirm("¿Está seguro de borrar la cita?")
      ) {
        let key = this.props.event.id;
        deleteAppointment(key);
      }
      this.setState({ deletePrompt: true });
    };

    handleEdit = e => {
      let key = this.props.event.id;
      editAppointment(key);
    };

    handleCharge = e => {
      let key = this.props.event.id;
      chargeAppointment(key);
    };

    handleConfirm = e => {
      e.preventDefault();
      let key = e.target.previousSibling.id.split("confirm-")[1];
      confirmAppointment(key, !this.props.event.confirmed);
    };

    copy = e => {
      let copyText = e.target;
      var textArea = document.createElement("textarea");
      textArea.value = copyText.textContent;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("Copy");
      textArea.remove();
      alert("Copiado al portapapeles");
    };

    componentDidMount() {
      const triggers = Array.from(document.getElementsByClassName("rbc-event"));

      const handleEnter = e => {
        e.target.classList.add("trigger-enter");
        setTimeout(() => {
          e.target.classList.add("trigger-enter-active");
        }, 50);
      };

      const handleLeave = e => {
        e.target.classList.remove("trigger-enter");
        setTimeout(() => {
          e.target.classList.remove("trigger-enter-active");
        }, 50);
      };

      triggers.forEach(trigger => {
        trigger.addEventListener("mouseenter", handleEnter);
        trigger.addEventListener("mouseleave", handleLeave);
      });
    }

    render() {
      let start = this.props.event.start.toLocaleTimeString();
      start = start.slice(0, -3);

      let end = this.props.event.end.toLocaleTimeString();
      end = end.slice(0, -3);

      if (individual) {
        return (
          <div
            className={`${this.props.event.confirmed ? "confirmed" : ""} ${
              this.props.event.finished ? "finished" : "not-finished"
            } ${this.props.event.consultorio &&
              converter.toWords(this.props.event.consultorio)}`}
          >
            <div className="rbc-event-preview">
              <div className="rbc-event-preview-left">{start}</div>
            </div>
            <div
              className="hover-event-card"
              data-day={this.props.event.start.toLocaleString(
                window.navigator.language,
                { weekday: "long" }
              )}
            >
              <div className="hover-event-card-doctor">
                <div className="hover-event-card-doctor-left">
                  <strong>Dr. {this.props.event.doctor.name} </strong>
                  <span className="hover-event-card-consultorio">
                    en Consultorio{" "}
                    <span className="hover-event-card-consultorio-number">
                      {this.props.event.consultorio || "0"}
                    </span>
                  </span>
                </div>
                <div
                  className="hover-event-card-doctor-right"
                  onClick={this.handleClose}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    className="heroicon-close"
                  >
                    <path
                      className="heroicon-ui"
                      d="M4.93 19.07A10 10 0 1 1 19.07 4.93 10 10 0 0 1 4.93 19.07zm1.41-1.41A8 8 0 1 0 17.66 6.34 8 8 0 0 0 6.34 17.66zM13.41 12l1.42 1.41a1 1 0 1 1-1.42 1.42L12 13.4l-1.41 1.42a1 1 0 1 1-1.42-1.42L10.6 12l-1.42-1.41a1 1 0 1 1 1.42-1.42L12 10.6l1.41-1.42a1 1 0 1 1 1.42 1.42L13.4 12z"
                    />
                  </svg>
                </div>
              </div>
              <div className="hover-event-card-time">
                {start + " - " + end}{" "}
              </div>
              <div className="hover-event-card-patient-info">
                <div className="hover-event-card-title">
                  {this.props.title || "Sin título"}
                </div>
                <div className="hover-event-card-patient-name">
                  <strong>
                    {this.props.event.patient.name || "Sin paciente"}
                  </strong>
                </div>
                <div className="hover-event-card-patient-phone">
                  <span onClick={this.copy}>
                    {this.props.event.patient.phone || "Sin teléfono"}
                  </span>
                  <span onClick={this.handleConfirm}>
                    <input
                      type="checkbox"
                      className="filled-in"
                      checked={this.props.event.confirmed ? true : false}
                      id={`confirm-${this.props.event.id}`}
                    />
                    <span htmlFor={`confirm-${this.props.event.id}`}>
                      Confirmado
                    </span>
                  </span>
                </div>
              </div>

              <span className="arrow" />
            </div>
          </div>
        );
      }
      return (
        <div
          className={`${this.props.event.confirmed ? "confirmed" : ""} ${
            this.props.event.finished ? "finished" : "not-finished"
          } ${this.props.event.consultorio &&
            converter.toWords(this.props.event.consultorio)}`}
        >
          <div className="rbc-event-preview">
            <div className="rbc-event-preview-left">{start}</div>
            <div className="rbc-event-preview-right">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                className="heroicon-charge"
                onClick={this.handleCharge}
                width="24"
                height="24"
              >
                <path
                  className="heroicon-ui"
                  d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm1-11v2h1a3 3 0 0 1 0 6h-1v1a1 1 0 0 1-2 0v-1H8a1 1 0 0 1 0-2h3v-2h-1a3 3 0 0 1 0-6h1V6a1 1 0 0 1 2 0v1h3a1 1 0 0 1 0 2h-3zm-2 0h-1a1 1 0 1 0 0 2h1V9zm2 6h1a1 1 0 0 0 0-2h-1v2z"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                className="heroicon-edit"
                onClick={this.handleEdit}
              >
                <path
                  className="heroicon-ui"
                  d="M6.3 12.3l10-10a1 1 0 0 1 1.4 0l4 4a1 1 0 0 1 0 1.4l-10 10a1 1 0 0 1-.7.3H7a1 1 0 0 1-1-1v-4a1 1 0 0 1 .3-.7zM8 16h2.59l9-9L17 4.41l-9 9V16zm10-2a1 1 0 0 1 2 0v6a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6c0-1.1.9-2 2-2h6a1 1 0 0 1 0 2H4v14h14v-6z"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                className="heroicon-delete"
                onClick={this.handleDelete}
              >
                <path
                  className="heroicon-ui"
                  d="M8 6V4c0-1.1.9-2 2-2h4a2 2 0 0 1 2 2v2h5a1 1 0 0 1 0 2h-1v12a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V8H3a1 1 0 1 1 0-2h5zM6 8v12h12V8H6zm8-2V4h-4v2h4zm-4 4a1 1 0 0 1 1 1v6a1 1 0 0 1-2 0v-6a1 1 0 0 1 1-1zm4 0a1 1 0 0 1 1 1v6a1 1 0 0 1-2 0v-6a1 1 0 0 1 1-1z"
                />
              </svg>
            </div>
          </div>
          <div
            className="hover-event-card"
            data-day={this.props.event.start.toLocaleString(
              window.navigator.language,
              { weekday: "long" }
            )}
          >
            <div className="hover-event-card-doctor">
              <div className="hover-event-card-doctor-left">
                <strong>Dr. {this.props.event.doctor.name} </strong>
                <span className="hover-event-card-consultorio">
                  en Consultorio{" "}
                  <span className="hover-event-card-consultorio-number">
                    {this.props.event.consultorio || "0"}
                  </span>
                </span>
              </div>
              <div
                className="hover-event-card-doctor-right"
                onClick={this.handleClose}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="heroicon-close"
                >
                  <path
                    className="heroicon-ui"
                    d="M4.93 19.07A10 10 0 1 1 19.07 4.93 10 10 0 0 1 4.93 19.07zm1.41-1.41A8 8 0 1 0 17.66 6.34 8 8 0 0 0 6.34 17.66zM13.41 12l1.42 1.41a1 1 0 1 1-1.42 1.42L12 13.4l-1.41 1.42a1 1 0 1 1-1.42-1.42L10.6 12l-1.42-1.41a1 1 0 1 1 1.42-1.42L12 10.6l1.41-1.42a1 1 0 1 1 1.42 1.42L13.4 12z"
                  />
                </svg>
              </div>
            </div>
            <div className="hover-event-card-time">{start + " - " + end} </div>
            <div className="hover-event-card-patient-info">
              <div className="hover-event-card-title">
                {this.props.title || "Sin título"}
              </div>
              <div className="hover-event-card-patient-name">
                <strong>
                  {this.props.event.patient.name || "Sin paciente"}
                </strong>
              </div>
              <div className="hover-event-card-patient-phone">
                <span onClick={this.copy}>
                  {this.props.event.patient.phone || "Sin teléfono"}
                </span>
                <span onClick={this.handleConfirm}>
                  <input
                    type="checkbox"
                    className="filled-in"
                    checked={this.props.event.confirmed ? true : false}
                    id={`confirm-${this.props.event.id}`}
                  />
                  <span htmlFor={`confirm-${this.props.event.id}`}>
                    Confirmado
                  </span>
                </span>
              </div>
            </div>

            <span className="arrow" />
          </div>
        </div>
      );
    }
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createAppointment: appointment => {
      dispatch(createAppointment(appointment));
    },
    deleteAppointment: appointmentID => {
      dispatch(deleteAppointment(appointmentID));
    },
    editAppointment: (appointmentID, appointment) => {
      dispatch(editAppointment(appointmentID, appointment));
    },
    createPatient: patient => {
      dispatch(createPatient(patient));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(
  class Calendar extends Component {
    constructor(props) {
      super(props);

      this.state = {
        editing: false,
        events: this.props.events || [],
        showingAppointment: false,
        errorMessage: false,
        currentTitle: "",
        selectedStart: moment().toDate(),
        selectedEnd: moment().toDate(),
        selectedSlots: [],
        patientName: "",
        patientPhone: "",
        patientMail: "",
        consultorio: "",
        doctor: props.doctor || {},
        doctorID: props.doctorID,
        patient: null,
        counter: 0,
        selected: {},
        view: props.defaultView,
        moneyCharged: null,
        editKey: "",
        addPatient: true
      };

      this.moveEvent = this.moveEvent.bind(this);
    }

    handleDataChange = e => {
      let property = e.target.dataset.statetarget;
      this.setState({ [property]: e.target.value });
    };

    componentWillReceiveProps(nextProps) {
      this.setState({
        doctor: nextProps.doctor,
        events: nextProps.events,
        doctorID: nextProps.doctorID,
        view: nextProps.defaultView
      });
    }

    componentDidMount() {
      var elements = document.querySelectorAll(".modal");
      let options = {};
      elements.forEach(element => {
        M.Modal.init(element, options);
      });
    }

    open = id => {
      let element = document.getElementById(id);
      let instance = M.Modal.getInstance(element);
      instance.open();
    };

    close = e => {
      e.preventDefault();
      let elements = document.querySelectorAll(".modal");

      elements.forEach(element => {
        let instance = M.Modal.getInstance(element);
        instance.close();
      });
    };

    formats = {
      dayHeaderFormat: "dddd DD MMMM",
      agendaTimeRangeFormat: ({ start, end }, culture, local) =>
        local.format(start, "HH:mm", culture) +
        "-" +
        local.format(end, "HH:mm", culture),
      agendaDateFormat: "DD-MMM",
      agendaHeaderFormat: ({ start, end }, culture, local) => {
        return (
          local.format(start, "MMMM DD", culture) +
          " - " +
          local.format(end, "MMMM DD", culture)
        );
      }
    };

    messages = {
      allDay: "Todo el día",
      previous: "Anterior",
      next: "Siguiente",
      today: "Hoy",
      month: "Mes",
      week: "Semana",
      day: "Día",
      agenda: "Agenda",
      date: "Fecha",
      time: "Tiempo",
      event: "Cita",
      showMore: total => `+ Ver ${total} citas más`,
      noEventsInRange: "😮 No hay citas esta semana"
    };

    localizer = BigCalendar.momentLocalizer(moment);

    onSelectSlot = variables => {
      if (this.state.view === "month") {
        return;
      }

      let isPossible = true;

      variables.slots.splice(-1, 1);

      variables.slots.every((slot, index) => {
        let slotElement = document.getElementsByClassName(slot.toISOString());

        if (slotElement.length === 0) {
          isPossible = false;
          return false;
        }

        if (slotElement[0].classList.contains("has-event")) {
          isPossible = false;
          return false;
        }

        return true;
      });

      if (
        isPossible &&
        !this.isEmpty(this.state.doctor) &&
        variables.start > moment.now()
      ) {
        this.setState({
          selectedStart: variables.start,
          selectedEnd: variables.end,
          selectedSlots: variables.slots,
          showingAppointment: true
        });
        this.open("add-appointment-modal");
      } else {
        this.setState({
          errorMessage: true
        });
      }
    };

    onSelectEvent = e => {
      if (this.state.view !== "month") {
        this.setState({ selected: e });
      }
    };

    onView = newView => {
      this.setState({ selected: {}, view: newView });
    };

    isEmpty = obj => {
      for (var key in obj) {
        if (obj.hasOwnProperty(key)) return false;
      }
      return true;
    };

    slotPropGetter = slot => {
      let classes = [];

      classes.push(slot.toISOString());

      if (this.isBusinessHour(slot)) {
        classes.push("business");
      } else {
        classes.push("not-business");
      }

      if (this.isEventHour(slot)) {
        classes.push("has-event");
      }

      return { className: classes };
    };

    isEventHour = slotDate => {
      let result = false;

      if (this.state.events) {
        this.state.events.forEach(event => {
          event.slots.forEach(slot => {
            if (slot.toDate().toISOString() === slotDate.toISOString()) {
              result = true;
            }
          });
        });
      }

      return result;
    };

    isBusinessHour = slotDate => {
      let answer = false;

      if (!this.isEmpty(this.props.doctor)) {
        let doctorBusinessHours = this.props.doctor.businessHours;
        for (let key in doctorBusinessHours) {
          if (doctorBusinessHours[key].length !== 0) {
            doctorBusinessHours[key].forEach(element => {
              let elementHoursStart = parseInt(element.start.split(".")[0]);
              let elementMinutesStart = element.start.split(".")[1] ? 30 : 0;
              let elementHoursEnd = parseInt(element.end.split(".")[0]);
              let elementMinutesEnd = element.end.split(".")[1] ? 30 : 0;
              let elementDay = parseInt(key);

              let elementStartDate = new Date(
                slotDate.getFullYear(),
                slotDate.getMonth(),
                slotDate.getDate(),
                elementHoursStart,
                elementMinutesStart,
                0,
                0
              );

              let elementEndDate = new Date(
                slotDate.getFullYear(),
                slotDate.getMonth(),
                slotDate.getDate(),
                elementHoursEnd,
                elementMinutesEnd,
                0,
                0
              );

              if (slotDate.getDay() !== elementDay) {
                return;
              }

              let slotDateEnd = new Date(slotDate.getTime() + 30 * 60000);

              if (
                slotDate >= elementStartDate &&
                slotDate <= elementEndDate &&
                slotDateEnd >= elementStartDate &&
                slotDateEnd <= elementEndDate
              ) {
                answer = true;
              }
            });
          }
        }
        return answer;
      } else {
        return true;
      }
    };

    addAppointment = e => {
      e.preventDefault();

      let {
        selectedStart,
        selectedEnd,
        currentTitle,
        consultorio,
        doctorID,
        doctor,
        patientMail,
        patientName,
        patientPhone,
        selectedSlots,
        addPatient
      } = this.state;

      delete doctor.businessHours;
      delete doctor.createdAt;
      delete doctor.specialties;

      let patient = {
        mail: patientMail,
        name: patientName,
        phone: patientPhone
      };

      if (addPatient) {
        this.props.createPatient(patient);
      }

      let newAppointment = {
        start: selectedStart,
        end: selectedEnd,
        title: currentTitle,
        consultorio: consultorio,
        doctorID: doctorID,
        doctor: doctor,
        patient: patient,
        slots: selectedSlots
      };

      let element = document.getElementById("add-appointment-modal");
      let instance = M.Modal.getInstance(element);
      instance.close();

      if (doctor.id === doctorID) {
        this.props.createAppointment(newAppointment);
      }

      this.setState({
        patientName: "",
        patientPhone: "",
        patientMail: "",
        consultorio: "",
        currentTitle: ""
      });
    };

    confirmAppointment = (key, confirmed) => {
      let appointment = {
        confirmed: confirmed
      };

      this.props.editAppointment(key, appointment);
    };

    chargeAppointment = key => {
      this.setState({ editKey: key, moneyCharged: null });
      this.open("edit-appointment-modal");
    };

    fireChargeAppointment = e => {
      e.preventDefault();

      let type = e.target.id.split("-")[0];

      if (
        this.state.moneyCharged <= 0 ||
        !this.state.moneyCharged ||
        isNaN(this.state.moneyCharged)
      ) {
        return this.close(e);
      }
      if (this.state.moneyCharged < this.state.selected.doctor.price) {
        if (
          window.confirm(
            `¿Deseas cobrar ${
              this.state.moneyCharged
                ? this.state.moneyCharged + " MXN"
                : "nada"
            } (Menor al precio regular del doctor) por la cita?`
          )
        ) {
          let appointment = {
            charged: this.state.moneyCharged,
            finished: true,
            type: type
          };
          this.props.editAppointment(this.state.editKey, appointment);
        }
      } else {
        if (
          window.confirm(
            `¿Deseas cobrar ${
              this.state.moneyCharged
                ? this.state.moneyCharged + " MXN"
                : "nada"
            } por la cita?`
          )
        ) {
          let appointment = {
            charged: this.state.moneyCharged,
            finished: true,
            type: type
          };
          this.props.editAppointment(this.state.editKey, appointment);
        }
      }
      this.setState({ moneyCharged: null });
      this.close(e);
    };

    editAppointment = key => {
      this.open("add-appointment-modal");
      let event = this.props.events.find(event => {
        return event.id === key;
      });

      this.setState({
        editing: true,
        editKey: key,
        currentTitle: event.title,
        patientName: event.patient.name,
        patientPhone: event.patient.phone,
        patientMail: event.patient.mail,
        selectedSlots: event.slots,
        selectedStart: event.start,
        selectedEnd: event.end,
        consultorio: event.consultorio
      });
    };

    fireEditAppointment = e => {
      e.preventDefault();
      let appointment = {
        title: this.state.currentTitle,
        patient: {
          name: this.state.patientName,
          phone: this.state.patientPhone,
          mail: this.state.patientMail
        },
        consultorio: this.state.consultorio
      };
      this.props.editAppointment(this.state.editKey, appointment);
      this.setState({
        editing: false,
        patientName: "",
        patientPhone: "",
        patientMail: "",
        consultorio: "",
        currentTitle: ""
      });
      this.close(e);
    };

    handlePatientLookup = () => {
      let { patientPhone } = this.state;
      let { patients } = this.props;
      let foundPatient = null;

      patients.forEach(patient => {
        if (patientPhone === patient.phone) {
          foundPatient = patient;
        }
      });

      if (foundPatient) {
        this.setState({
          patientMail: foundPatient.mail,
          patientName: foundPatient.name,
          addPatient: false
        });
      }
    };

    moveEvent({ event, start, end }) {
      if (
        window.confirm(
          `Deseas mover la cita de ${start.toLocaleString()} a ${end.toLocaleString()}`
        )
      ) {
        const { events } = this.props;

        const idx = events.indexOf(event);

        const updatedEvent = { ...event, start, end };

        const nextEvents = [...events];
        nextEvents.splice(idx, 1, updatedEvent);

        this.setState({
          events: nextEvents
        });

        this.props.editAppointment(updatedEvent.id, updatedEvent);
      }
    }

    resizeEvent = ({ event, start, end }) => {
      const { events } = this.props;

      const idx = events.indexOf(event);

      const updatedEvent = { ...event, start, end };

      const nextEvents = [...events];
      nextEvents.splice(idx, 1, updatedEvent);

      this.setState({
        events: nextEvents
      });

      this.props.editAppointment(updatedEvent.id, updatedEvent);
    };

    render() {
      let { deleteAppointment, history } = this.props;
      let editAppointment = this.editAppointment;
      let confirmAppointment = this.confirmAppointment;
      let chargeAppointment = this.chargeAppointment;
      let individual = this.props.individual;

      if (individual) {
        return (
          <div className="big-calendar-wrapper">
            <div className="individual-top-div" />
            <BigCalendar
              localizer={this.localizer}
              events={
                this.state.events
                  ? this.state.events
                      .map(event => {
                        let mapped = event;
                        try {
                          mapped.start = mapped.start.toDate();
                          mapped.end = mapped.end.toDate();
                        } catch (error) {}
                        return mapped;
                      })
                      .sort((a, b) => {
                        return a.consultorio > b.consultorio;
                      })
                  : []
              }
              startAccessor="start"
              endAccessor="end"
              messages={this.messages}
              slotPropGetter={this.slotPropGetter}
              popup={true}
              formats={this.formats}
              length={7}
              step={
                this.state.doctor &&
                this.state.doctor.specialties &&
                (this.state.doctor.specialties.includes("Dermatología") ||
                  this.state.doctor.specialties.includes("Ginecología"))
                  ? 20
                  : 30
              }
              components={{
                event: customEvent({
                  individual,
                  deleteAppointment,
                  history,
                  editAppointment,
                  chargeAppointment,
                  confirmAppointment
                })
              }}
              defaultDate={new Date()}
              view={this.state.view}
              onView={this.onView}
              selected={this.state.selected}
              min={new Date("2018, 1, 7, 08:00")}
            />
          </div>
        );
      }

      return (
        <div className="big-calendar-wrapper">
          <EditAppointment
            selected={this.state.selected}
            edit={this.fireChargeAppointment}
            close={this.close}
            handleDataChange={this.handleDataChange}
            moneyCharged={this.state.moneyCharged}
          />
          <AddAppointment
            editing={this.state.editing}
            start={this.state.selectedStart}
            end={this.state.selectedEnd}
            currentTitle={this.state.currentTitle}
            handleDataChange={this.handleDataChange}
            consultorio={this.state.consultorio}
            patientName={this.state.patientName}
            patientPhone={this.state.patientPhone}
            patientMail={this.state.patientMail}
            close={this.close}
            add={this.addAppointment}
            edit={this.fireEditAppointment}
            handlePatientLookup={this.handlePatientLookup}
          />
          <DoctorPicker doctor={this.state.doctorID} history={history} />
          <DragAndDropCalendar
            localizer={this.localizer}
            resizable
            onEventDrop={this.moveEvent}
            onEventResize={this.resizeEvent}
            events={
              this.state.events
                ? this.state.events
                    .map(event => {
                      let mapped = event;
                      try {
                        mapped.start = mapped.start.toDate();
                        mapped.end = mapped.end.toDate();
                      } catch (error) {}
                      return mapped;
                    })
                    .sort((a, b) => {
                      return a.consultorio > b.consultorio;
                    })
                : []
            }
            startAccessor="start"
            endAccessor="end"
            selectable
            onSelectSlot={this.onSelectSlot}
            onSelectEvent={this.onSelectEvent}
            messages={this.messages}
            slotPropGetter={this.slotPropGetter}
            popup={true}
            formats={this.formats}
            length={7}
            step={
              this.state.doctor &&
              this.state.doctor.specialties &&
              (this.state.doctor.specialties.includes("Dermatología") ||
                this.state.doctor.specialties.includes("Ginecología"))
                ? 20
                : 30
            }
            components={{
              event: customEvent({
                deleteAppointment,
                history,
                editAppointment,
                chargeAppointment,
                confirmAppointment
              })
            }}
            defaultDate={new Date()}
            view={this.state.view}
            onView={this.onView}
            selected={this.state.selected}
            min={new Date("2018, 1, 7, 08:00")}
          />
        </div>
      );
    }
  }
);
